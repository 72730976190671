@import url('https://fonts.googleapis.com/css?family=Oxygen|Neucha');

body {
    margin: 0;
    padding: 0;
    font-family: 'Oxygen', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
    max-width: 100%;
}

label img {
    max-height: 2.5rem;
}

.handwritten {
    font-family: 'Neucha', cursive;
}

.min-h-100 {
    min-height: 100%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}